import React from 'react';
import './Internship.css';

const Internship = () => {
    const internshipList = [
        {
          title: 'Python Programming Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/lapin.jpg',
          registeredStudents: '4400+',
          projects: 13,
        },
        {
          title: 'Cyber Security Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/webin.jpg',
          registeredStudents: '3800+',
          projects: 10,
        },
        {
          title: 'Web Development Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/webint.jpg',
          registeredStudents: '5200+',
          projects: 15,
        },
        {
          title: 'App Development Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/appin.jpg',
          registeredStudents: '4100+',
          projects: 12,
        },
        {
          title: 'Data Science Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/tog.jpg',
          registeredStudents: '4700+',
          projects: 18,
        },
        {
          title: 'Machine Learning Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/machint.jpg',
          registeredStudents: '4500+',
          projects: 16,
        },
        {
          title: 'Artificial Intelligence Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/ai.jpg',
          registeredStudents: '4000+',
          projects: 14,
        },
        {
          title: 'Graphic Design Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/grapin.jpg',
          registeredStudents: '3500+',
          projects: 8,
        },
        {
          title: 'UI/UX Design Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/ui.png',
          registeredStudents: '3700+',
          projects: 9,
        },
        {
          title: 'Digital Marketing Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/dig.WebP',
          registeredStudents: '3900+',
          projects: 11,
        },
        {
          title: 'Blockchain Development Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/blo.jpg',
          registeredStudents: '3000+',
          projects: 7,
        },
        {
          title: 'Cloud Computing Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/clo.jpg',
          registeredStudents: '3400+',
          projects: 10,
        },
        {
          title: 'Big Data Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/bigd.png',
          registeredStudents: '3100+',
          projects: 8,
        },

        {
          title: 'Game Development Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/gam.jpg',
          registeredStudents: '2800+',
          projects: 6,
        },

        {
          title: 'IoT (Internet of Things) Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/iot.jpg',
          registeredStudents: '3200+',
          projects: 10,
        },
        {
          title: 'Robotics Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/rob.jpg',
          registeredStudents: '2500+',
          projects: 5,
        },
        {
          title: 'Full-Stack Development Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/full.png',
          registeredStudents: '4800+',
          projects: 20,
        },
        {
          title: 'DevOps Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/de.png',
          registeredStudents: '3300+',
          projects: 9,
        },
        {
          title: 'Network Engineering Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/net.jpg',
          registeredStudents: '2700+',
          projects: 7,
        },
        {
          title: 'Video Editing Internship',
          courseDuration: '04/06/08/12 weeks',
          image: '/images/vi.Webp',
          registeredStudents: '2200+',
          projects: 6,
        },
      ];
      
  return (
    <div className="internship-container">
      <h1 className="internship-title">Available Internship Positions</h1>
      <div className="internship-list">
        {internshipList.map((internship, index) => (
          <div key={index} className="internship-card">
            <img className="internship-image" src={internship.image} alt={internship.title} />

            {/* Internship Title and Course Duration */}
            <div className="card-content">
              <h2 className="internship-heading">{internship.title}</h2>
              {internship.courseDuration && (
                <p className="course-duration">
                  <strong>Course Duration:</strong> {internship.courseDuration}
                </p>
              )}

              {/* Register Button */}
              <a href="https://forms.gle/o8QdBoNqJfKvZoaU9" target="_blank" rel="noopener noreferrer">
                <button className="register-btn">Register Now</button>
              </a>
            </div>

            {/* Info about Students and Projects */}
            <div className="info-corner">
              <div className="info-item">
                <strong>{internship.registeredStudents} students </strong>
              </div>
              <div className="info-item">
                <strong>{internship.projects} projects</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>


  );
};

export default Internship;
